import React from 'react';
import '../assets/styles/VideosPage.css';

const VideosPage = () => {
  const videos = [
    { id: 1, title: 'Gandhi Ashram tour video', url: require('../assets/images/ashram1.mp4') },
    { id: 2, title: 'Gandhi Ashram Product Video', url: require('../assets/images/product.mp4') }
    // Add more video objects as needed
  ];

  return (
    <div className="videos-page">
      <div className="video-gallery">
        {videos.map(video => (
          <div key={video.id} className="video-item">
            <h3>{video.title}</h3>
            <video width="320" height="240" controls>
              <source src={video.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
    </div>
  );
};




export default VideosPage;

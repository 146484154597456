import React from 'react';
import NavBar from './NavBar';
import '../assets/styles/styles.css';

const Header = () => (
  <header>
   
    
    <NavBar />
  </header>
);

export default Header;

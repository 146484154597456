import React from 'react';
import '../assets/styles/aboutus.css';

const Aboutus = () => {
  return (
    <div className="aboutus-container">
      <h1>About Us</h1>

    


      <h2>Our Head Office Location</h2>
      <div className="map-container">
        <iframe
          title="Tiruchengode Gandhi Ashramam"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d309.40723467480976!2d77.945847!3d11.3213718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babd861e5b10dd5%3A0xe4a8af515ce5d6bc!2sTiruchengode%20Gandhi%20Ashramam!5e1!3m2!1sen!2sin!4v1690801394602!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <h2>Our Salem Branch Location</h2>
      <div className="map-container">
        <iframe
          title="Tiruchengode Gandhi Ashramam"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d345.344795967599!2d78.16518814859462!3d11.655541036424248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf1cfcdd4b12d%3A0xd98292cedd3e6a6a!2sGandhi%20Ashram!5e1!3m2!1sen!2sin!4v1722847416282!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <h2>Gandhi Ashram Khadi Bhandar Location</h2>
      <div className="map-container">
        <iframe
          title="Tiruchengode Gandhi Ashramam"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d711.623019388976!2d77.89509344195301!3d11.380976405973858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba96190b4bcfb6d%3A0x4bd899079b32f8f0!2sgandhi%20ashram!5e1!3m2!1sen!2sin!4v1722848362581!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <h2>Attur Gandhi Ashram Khadi Bhandar Location</h2>
      <div className="map-container">
        <iframe
          title="Tiruchengode Gandhi Ashramam"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6572.39399101126!2d78.59749189362128!3d11.595793261379992!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab9c46780f9f3b%3A0x8b15dc456681307b!2sGANDHI%20ASHRAM%20KHADI%20BHANDAR!5e1!3m2!1sen!2sin!4v1722850026609!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <h2>Gandhi Ashram Sales Depot Namakkal</h2>
      <div className="map-container">
        <iframe
          title="Tiruchengode Gandhi Ashramam"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d177083.90980154127!2d78.10075740967504!3d11.227785816876459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babcf1dfe67a011%3A0x68ddbed691321a31!2sGandhi%20Ashram%20Khadi%20Bhandar!5e1!3m2!1sen!2sin!4v1722850799239!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>

      <h2>QR Code</h2>
      <div className="map-container">
        <img src={require('../assets/images/frame.png')} alt="Popup" className="small-image" />
      </div>

      <h2>Contact Us</h2>
      <p>Phone: (+91) 883 800 4541</p>
      <p>Email: tcdashram25@gmail.com</p>

      <h2>Contact Form</h2>
      <form className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="4" required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Aboutus;

import React, { useState } from 'react';
import '../assets/styles/product.css'; 


import img1 from '../assets/images/products/img1.jpg';
import img14 from '../assets/images/products/img14.jpg';
import img17 from '../assets/images/products/img17.jpg';
import img3 from '../assets/images/products/img3.jpg';
import img22 from '../assets/images/products/img22.jpg';
import img29 from '../assets/images/products/img29.jpg';
import img32 from '../assets/images/products/img32.jpg';
import img5 from '../assets/images/products/img5.jpg';
import img12 from '../assets/images/products/img12.jpg';
import img15 from '../assets/images/products/img15.jpg';
import img9 from '../assets/images/products/img9.jpg';
import img30 from '../assets/images/products/img30.jpg';
import img7 from '../assets/images/products/img7.jpg';
import img11 from '../assets/images/products/img11.jpg';
import img24 from '../assets/images/products/img24.jpg';
import img13 from '../assets/images/products/img13.jpg';
import img18 from '../assets/images/products/img18.jpg';
import file1 from '../assets/images/file_images/img8.jpeg';
import file2 from '../assets/images/file_images/img9.jpeg';
import file3 from '../assets/images/file_images/img10.jpeg';
import file4 from '../assets/images/file_images/img12.jpeg';
import s1 from '../assets/images/agarbathi/img2.jpeg';
import s2 from '../assets/images/agarbathi/img4.jpeg';
import s3 from '../assets/images/agarbathi/img5.jpeg';
import j1 from '../assets/images/agarbathi/img6.jpeg';
import j2 from '../assets/images/agarbathi/img1.jpeg';
import j3 from '../assets/images/agarbathi/img13.jpeg';
import a1 from '../assets/images/agarbathi/img10.jpeg';
import a2 from '../assets/images/agarbathi/img9.jpeg';
import a3 from '../assets/images/agarbathi/img14.jpeg';
import a4 from '../assets/images/agarbathi/img20.jpeg';
import soap from '../assets/images/soap1.jpeg';
import soap1 from '../assets/images/soap2.jpeg';






const products = [
  {
    id: 1,
    name: 'Thulsi Bath Soap',
    images: [img1, img14, img17]
  },
  {
    id: 2,
    name: 'Agmark Honey',
    images: [img3, img22, img29, img32]
  },
  {
    id: 3,
    name: 'Sandal Bath Soap',
    images: [img5, img12, img15 ]

  },

  {
    id: 4,
    name: 'Aloe Vera Bath Soap' ,
    images: [img9,img30]

  },
  {
    id: 5,
    name: 'Sopa' ,
    images: [img7, img11, img24]

  },
  {
    id: 6,
    name: 'Sampu' ,
    images: [img13, img18]

  },
  {
    id: 7,
    name: 'Files',
    images: [file1, file2,file3,file4]


  },
  {
    id: 8,
    name: 'Dhoop Samrani',
    images: [s1, s2, s3]
  },
  {
    id: 9,
    name: 'Javadhu',
    images: [j1, j2, j3]
  },

  {
    id: 10,
    name: 'Sambrani',
    images: [a1, a2, a3, a4]
  },
  {
    id:11,
    name: 'Soap',
    images: [soap, soap1]
  }
  

];

const Product = ({ name, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="product-container">
      <img
        src={images[currentImageIndex]}
        alt={name}
        className="product-image"
      />
      <div className="product-name">{name}</div>
      <div className="image-navigation">
        <button onClick={handlePrev}>Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

const ProductList = () => {
  return (
    <div>
      <h1 className="product-list-title">Our Gandhi Ashram Products</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {products.map((product) => (
          <Product key={product.id} name={product.name} images={product.images} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;

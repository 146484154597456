import React from 'react';
import gandhiImage from '../assets/images/vistors/image.png';
import gan1 from '../assets/images/img17.png';
import babuRajendraImage from '../assets/images/vistors/babu.png'; 
import srimathiImage from '../assets/images/vistors/kasturba.png'; 
import jawaharlalImage from '../assets/images/vistors/jawaharlal.png'; 
import shriLalImage from '../assets/images/vistors/lal.png'; 
import subramaniamImage from '../assets/images/vistors/subaramani.png'; 
import perunthalaivarImage from '../assets/images/vistors/perum.png'; 
import shahImage from '../assets/images/gandhi_ashram/img8.JPG'; 
import silambuImage from '../assets/images/vistors/siva.png'; 
import venkatramanImage from '../assets/images/vistors/venk.png';
import parabudasImage from '../assets/images/gandhi_ashram/img11.JPG'; 
import banwarilalImage from '../assets/images/vistors/ban.png';
import '../assets/styles/info.css';

const InfoPage = () => (
  <div className="main-container">
    <h1>Our Gandhi Ashram Visitor's Information</h1>
    <h3>LEADERS WHO VISITED THE ASHRAM</h3>

    <div className="info-vist">
      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${gandhiImage})` }}></div>
        <p className="leader-text">Mahatma Gandhiji, father of our Nation. Visit to the Ashram on 19.03.1925 – 21.03.1925</p>
      </div>


      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${gan1})` }}></div>
        <p className="leader-text">Mahatma Gandhiji, father of our Nation. Visit to the Ashram second time on 13.02.1934</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${babuRajendraImage})` }}></div>
        <p className="leader-text">Babu Rajendra Prasad, the First President of free India. Visit to the Ashram on 25.05.1927</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${srimathiImage})` }}></div>
        <p className="leader-text">Srimathi Kasturbha Gandhi Visited the Ashram on 18-12-1932 </p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${jawaharlalImage})` }}></div>
        <p className="leader-text">Jawaharlal Nehruji, the First Prime Minister of Free India. Visited the Ashram on 11-10-1936</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${shriLalImage})` }}></div>
        <p className="leader-text">Lal Bahadur Shastri, the Prime Minister of India. Visited the Ashram on 09.11.1953</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${subramaniamImage})` }}></div>
        <p className="leader-text">Shri.C.Subramaniam, the Central Finance Minister. Visited the Ashram on 14.02.1953</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${perunthalaivarImage})` }}></div>
        <p className="leader-text">Perunthalaivar Kamarajar .Visited the Ashram  on 01-08-1962 and 09-02-1975</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${shahImage})` }}></div>
        <p className="leader-text">His Excellency K.K.Shah, Governor of Tamilnadu</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${silambuImage})` }}></div>
        <p className="leader-text">Silambu Selvar M.P.Sivagnanam, Former speaker of legislative council. Visited the Ashram  on 09-01-1979 and 08-02-1981 </p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${venkatramanImage})` }}></div>
        <p className="leader-text">His Excellency R.Venkatraman, Vice President of India. Visited the Ashram on 05.06.1986</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${parabudasImage})` }}></div>
        <p className="leader-text">His Excellency Parabudas Batwari, Governor of Tamilnadu</p>
      </div>

      <div className="leader">
        <div className="leader-image" style={{ backgroundImage: `url(${banwarilalImage})` }}></div>
        <p className="leader-text">His Excellency.Banwarilal Purohit, Governor of Tamilnadu. Vis the Ashram on 22-06-2018</p>
      </div>
    </div>
  </div>
);

export default InfoPage;

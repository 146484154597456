import React from 'react';
import '../assets/styles/styles.css';

const Footer = () => (
  <footer>
    <p>&copy; 2024 Gandhi Ashram Tiruchengode</p>
    
  </footer>
);

export default Footer;

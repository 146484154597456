import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/gallery.css';
import '../assets/styles/gallery-mobile.css';
import dump from '../assets/images/gandhi_ashram/img1.JPG';

const GalleryPage = () => {
  return (
    <div className="con">
      <h2>Gallery</h2>
      <div className="gallery-options">
        <Link to="/gallery/photos" className="gallery-option">
          <div className="option-image" style={{ backgroundImage: `url(${dump})`}}></div>
          <h3>Photos</h3>
        </Link>
        <Link to="/gallery/videos" className="gallery-option">
          <div className="option-image" style={{ backgroundImage: `url(${dump})`}}></div>
          <h3>Videos</h3>
        </Link>
      </div>
    </div>
  );
};

export default GalleryPage;

import React from 'react';
import '../assets/styles/styles.css';
import '../assets/styles/product.css';
import Product from '../components/Product'; 

const ProductPage = () => {
  return (
    <div className="container">
      <Product /> 
    </div>
  );
};

export default ProductPage;

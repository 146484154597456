import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/navbar.css';

// Import the logo image
import logo from '../assets/images/icons/logo.png';

const NavBar = () => (
  <nav>
    <ul>
      <li className="logo-container">
        <Link to='/'>
          <img src={logo} alt="Website Logo" className="logo" />
          </Link>
        
      </li>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/history">History</Link>
      </li>
      <li>
        <Link to="/gallery">Gallery</Link>
      </li>
      <li>
        <Link to="/info">Visitor's Information</Link>
      </li>
      <li>
        <Link to="/products">Products</Link>
      </li>
      <li>
        <Link to="/aboutus">About Us</Link>
      </li>
    </ul>
  </nav>
);

export default NavBar;

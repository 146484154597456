import React from 'react';
import { Route, Routes as R } from 'react-router-dom';
import HomePage from './pages/HomePage';
import HistoryPage from './pages/HistoryPage';
import GalleryPage from './pages/GalleryPage';
import PhotosPage from './pages/PhotosPage';
import VideosPage from './pages/VideosPage';
import InfoPage from './pages/InfoPage';
import ProductPage from './pages/ProductPage';
import Aboutus from './pages/Aboutus';
import './assets/styles/styles.css';

const Routes = () => (
  <R>
    <Route path="/" element={<HomePage />} />
    <Route path="/history" element={<HistoryPage />} />
    <Route path="/gallery" element={<GalleryPage />} />
    <Route path="/gallery/photos" element={<PhotosPage />} />
    <Route path="/gallery/videos" element={<VideosPage />} />
    <Route path="/info" element={<InfoPage />} />
    <Route path="/products" element={<ProductPage />} />
    <Route path="/aboutus" element={<Aboutus />} />
  </R>
);

export default Routes;

import React from 'react';
import HistoryBar from '../components/HistoryBar';
import '../assets/styles/history.css';


const HistoryPage = () => (
  <div className="con">
    <h1>Our Gandhi Ashram History </h1>
    <HistoryBar />
  
  </div>
);

export default HistoryPage;

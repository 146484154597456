import React from 'react';
import '../assets/styles/history.css';


const HistoryBar = () => (
  <div className='container'>
    <div className='heading'>
          <h1>Shri.C.Rajagopalachari @Rajaji</h1>
        </div>
    <div className='photos'>
      <img src={require('../assets/images/rajaj1.jpg')} alt='rajaji' />
    </div>

    <div className='info'>
      <p>
        In order to conduct field trials of Gandhian Constructive Programs, The Gandhi Ashram - Tiruchengode, Tamilnadu was founded by Shri.C.Rajagopalachari  @Rajaji on 06-02-1925 as the South Indian Branch of Gandhi Seva Sangh of Wardha.
        The Ashram was located on 4 acres of land denoted by the then Jamindhar of the Village, Sri.Rathinasabapathi Goundar.<br></br>
        Shri.Rajaji lived in the Ashram, which is situated 11-kms from Tiruchengode at in Pudupalayam Village, in a small tiled house of 10-feet by 17-feet for 10-years from 1924-34.
        Along with Shri.K.Santhanam (later served as Lt.Governer), Shri.Dr.G.Ramachandran (later served as KVIC Chairman) and the other band of
        dedicated workers asked the people to get down the charkhas they were using before the British rule and supplied cotton to 13,000 families every week
        through bullock carts and procured the yarn they spun.<br></br>
        The yarn so collected was given to the weavers and hand woven Khadi was sold by Rajaji and others. For transport, only bullock carts were available.
        The area is a drought prone rural one and people were very much affected by the frequent failure of monsoon resulting misery and migration to Ceylon
        (SriLanka).<br></br> In those days 19np (Now it seems to be a meek amount) was an amount from which the weekly corus of a family can be purchased from the weekly shandy.
        But due to continuous non availability of this amount and the interest burden drove away the people to SriLanka. In these circumstances, the Ashram was started
        to mitigate their sufferings<br></br>
        Unfortunately the men folk of the family took away the hard earned money, earned by the women folk for consumption of liquor.
        On hearing this, Rajaji felt very much and pained. He had sleepless nights. Then he did propaganda in the villages with lanterns and cartoons depicting evils of liquor.
        He took with himfor the prohibition propaganda, the local Zamindar Shri. Rathinasabhapathi Gounder.<br></br>

        A Tamil journal “Vimochanam “was also published by the Ashram for whichthe notable writer “Kalki” was very much associated.
        By this propaganda the men folk of the family gave up drinking liquor and the shops were closed by the British government through Rajaji’s effort as from 1-4-1930 around village of Ashram. This has helped the rural folk very much to recover from starvation.

        <br></br>

        With the formation of the Khadi and village Industries Commission, the Gandhi Ashram ,Tiruchengode became a certified  Institution under   Khadi and village Industries Commission.
        The Ashram has been registered as a society under the societies registration act 1860.
        Sri.C.Rajagopalachariyar @ Rajaji,  Sri.M.S.Narayana Rao, Sri R.S.Dhotre, Sri.C.R.Narasimhan, Justice R.Sengottuvelan, Sri.A.P.Muthusamy IAS (R),  Thiyaki.Sri.K.Lakshmi kandan Bharathi IAS (R), Sri.A.Devaraj,  Sri.Haji Sulthan Ahamad Ismail and Sri.K.Chidambaram were some of the memorable Veteran workers who have contributed to the development of the Ashram.
        <br></br>
        The Ashram is manufacturing and selling Khadi and Village Industries Products like Cotton Khadi, Silk Khadi, Ployvastra, Laundry soap, Toilet Soap, Agarbathi, Handmade Paper and Files, Steel Furniture, Non edible oil and cake, Honey edible oils and other village industries product.
        <br></br>
        The Ashram has 12 Production Centers and 22 direct sales outlets in Namakkal, Salem, Dharmapuri, Krishnagiri, Erode, Coimbatore, and Trichy  Districts.
        The Ashram has achieved a production of 548.64lakhs and sale of Rs.993.75 lakhs during 2017-18. The Ashram Provides  regular employment to 95 Persons and employment and livelihood to about 650 rural artisans mostly women.
        The Government of India, through Khadi and Industries Commission, has advanced a direct loan of Rs.173.33lakhs towards working capital for
        Khadi and village Industries activities of the Ashram. Under the Interest subsidy scheme the KVIC helped the Ashram to get a bank finance Rs.117.27 lakhs from Nationalized
        Banks at a subsidized rate of interest of 4%, reimbursing the balance interest to the Banks.
     
        <br></br>
      
        This institution has been very successful in its endeavor in improving the rural economy.
        It is easily the envy of even corporate houses running large industries in mega cities.
        Today the ashram has an annual turnover of Rs. 100 million. It has about 100 centers of activity spread over a region of a radius of 62.5 miles around the ashram.
        It gives employment to about 4000 villagers, most of whom are not well educated.

        <div className='heading'>
          <h1>Shri. Rathna Sabapathy Gounder</h1>
        </div>
        <br></br>
        <div className='photos'>
        <img src={require('../assets/images/gounder.jpg')} alt="sample" />
      </div>
        
        The person who helped Rajaji in starting the Ashram and made all of the above possible was a good Samaritan named Zamindar Sri.
        RathnaSabapathy Gounder.<br></br> When Rajaji told Shri Rathnasabapathy Gounder about the need of a land for starting a ashram, Mr.Gounder spontaneously offered 4 Acres of his land.
        He not only gave the land but also allowed all the workers of the ashram to stay at his spacious Bungalow with out any caste creed or religious
        inhibitions. He also participated in the Indian struggle for Independence actively.<br></br>
        Mr.Rathna Sabapathy Gounder also had the privilege of mahatma Gandhi visiting his residence at Puduppalayam, s small town near
        Tiruchengodu, on 20th March 1925. Mahatma Gandhi also appreciated him for using and promoting kadhi in spite of being very rich.
      </p>
    </div>
  </div>
);

export default HistoryBar;

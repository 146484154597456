import React from 'react';
import '../assets/styles/styles.css';

const HomeViewersBar = () => (
  <div className="con">
    
  </div>
);

export default HomeViewersBar;
